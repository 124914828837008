.cursor-pointer{
  cursor: pointer !important;
}

.btn-appear {
  transition: 0.5s;
  opacity: 0 !important;
  border: 0;
}

.card-product-grid:hover .btn-appear{
  transition: .9s;
  opacity: 1 !important;
}

.preview-img {
  height: 160px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

err {
  color: red !important;
  font-size: small !important;
}

.err {
  color: red !important;
  font-size: small !important;
}

.bg-err {
  background-color: #FFE5E5 !important;
}

.bg-lightest {
  background-color: rgb(240, 240, 240);
}

.ellipse-text {
  text-overflow: ellipsis !important;
  white-space: nowrap !important; 
  overflow: hidden !important;
}

